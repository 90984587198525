import React from 'react';
import { Header, Segment } from 'semantic-ui-react';

const BasicsHeader = ({ basics }) => (
  <Segment id="nameLabel" className="sectionWrapper">
    <Header as="h1" id="name" className='name'>{basics.name}</Header>
    <Header as="h2" id="label" className='label'>{basics.label}</Header>
  </Segment>
);

export default BasicsHeader;
