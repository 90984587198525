import React from 'react';
import { Container, Header, Button, Image, Segment } from 'semantic-ui-react';

const Index = ({ summary }) => {
  return (
    <Container textAlign="center" style={{ marginTop: '50px' }}>
      <Segment raised padded>
        <Header as="h1" style={{ marginTop: '20px' }}>
          ViewResu.me
        </Header>
        <div style={{ maxWidth: '500px', margin: '0 auto' }}>
          <p style={{ fontSize: '16px', margin: '20px 0' }}>
            ViewResu.me is a dedicated platform for displaying resumes crafted at <a href='https://aloharesume.com'>Aloha Resume</a>. For further information, please verify the provided link or contact the originator.
          </p>
        </div>
        <Image src="/logo.png" size="small" centered />
        <br />
        <Button primary size="large" as="a" href="https://aloharesume.com">
          Explore Aloha Resume
        </Button>
        <Button primary size="large" as="a" href="/mark-w-breneman/director-of-digital-services">
         Example Resume
        </Button>
      </Segment>
    </Container>
  );
};

export default Index;
