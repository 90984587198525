import React from 'react';
import { Header, Item, Segment } from 'semantic-ui-react';

const References = ({ dataArr, name }) => {
  if (!dataArr){return '';}
  const renderData = () => dataArr.map(data => (
    <Item key={data.name}>
      <Item.Content>
        <Header as='h3'>
          {data.name}
          <Header.Subheader>{data.reference}</Header.Subheader>
        </Header>
      </Item.Content>
    </Item>
  ));



  return (
    <Segment id={name} className="sectionWrapper">
      <Header as='h2' className="sectionLabel">{name}</Header>
      <Item.Group >{renderData()}</Item.Group>
    </Segment>
  );
};

export default References;
