import React from 'react';
import { Header, Item, List, Icon, Segment, Container } from 'semantic-ui-react';

const Education = ({ dataArr, name }) => {
  if (!dataArr){return '';}
  const renderData = () => dataArr.map(data => {
    const startDateYear = new Date(data.startDate).getFullYear();
    const endDateYear = data.endDate ? ` - ${new Date(data.endDate).getFullYear()}` : " - current";

    return (
      <Item key={data.institution}>
        <Item.Content>
          <Item.Header>
            <Header as='h3' className='schoolName'>
              {data.institution}
              <Header.Subheader>
                {startDateYear}{endDateYear}
              </Header.Subheader>
            </Header>
          </Item.Header>
          <Item.Meta>
            <span className='cinema'>{data.area} - {data.studyType}</span>
          </Item.Meta>
          {data.gpa && <Item.Description>GPA: {data.gpa}</Item.Description>}
          <Item.Extra>
            {data.courses && data.courses.length > 0 &&
              <List>
                <Header sub>Courses</Header>
                <List.List>
                  {data.courses.map((course, index) => (
                    <List.Item key={index}>
                      <Icon name='right triangle' />
                      {course}
                    </List.Item>
                  ))}
                </List.List>
              </List>
            }
          </Item.Extra>
        </Item.Content>
      </Item>
    );
  });



  return (
    <Segment id={name} className="sectionWrapper">
      <Header as='h2' className="sectionLabel">{name}</Header>
      <Item.Group >{renderData()}</Item.Group>
    </Segment>
  );
};

export default Education;
