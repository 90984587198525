import React from 'react';
import { Container, Header } from 'semantic-ui-react';

const About = ({ summary, name }) => {
  return (
    <div id='About' className="segment sectionWrapper">
      <Header as='h2' className="sectionLabel">
        {name}
      </Header>
      <div className='sectionBody'>
        <p>{summary}</p>
      </div>
    </div>
  );
};

export default About;
