import React from 'react';
import { Container, Icon, Header, Segment } from 'semantic-ui-react';

const Profiles = ({ profiles, name }) => {
  if (!profiles){return '';}
  const renderProfiles = () => profiles.map(profile => {
    let iconName = profile.network.toLowerCase();
    const displayText = iconName.includes("web") ? profile.url : profile.username;
    iconName = iconName === 'github' ? iconName : iconName.includes("web") ? 'globe' : iconName;

    return (
      <div key={iconName}>
        <a href={profile.url} target='_blank'>
          <Icon name={iconName} /> {displayText}
        </a>
      </div>
    );
  });



  return (
    <Segment id={name} className="sectionWrapper">
      <Header as='h2' className="sectionLabel">{name}</Header>
      <div className='sectionBody'>
        {renderProfiles()}
      </div>
    </Segment>
  );
};

export default Profiles;
