import React from 'react';
import { Header, Item, Segment } from 'semantic-ui-react';

const Languages = ({ dataArr, name }) => {
  if (!dataArr){return '';}
  const fluencyVisual = {
    "A1": "Beginner (A1)",
    "A2": "Elementary (A2)",
    "B1": "Intermediate (B1)",
    "B2": "Upper Intermediate / Business Level (B2)",
    "C1": "Advanced (C1)",
    "C2": "Proficient / Native (C2)"
  };

  const renderData = () => dataArr.map(data => (
    <Item key={data.language}>
      <Item.Content>
        <Header as='h3' className='languageName'>{data.language}: { fluencyVisual[data.fluency] || data.fluency}
          </Header>
      </Item.Content>
    </Item>
  ));



  return (
    <Segment id={name} className="sectionWrapper">
      <Header as='h2' className="sectionLabel">{name}</Header>
      <Item.Group >{renderData()}</Item.Group>
    </Segment>
  );
};

export default Languages;
