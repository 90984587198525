import React, { useState, useEffect } from 'react';
import { Grid, Tab } from 'semantic-ui-react';

import Contact from './contact';
import Website from './website';
import About from './about';
import Profiles from './profiles';
import DataCard from './dataCard';
import Education from './education';
import Certificates from './certificates';
import Awards from './awards';
import Publications from './publications';
import Skills from './skills';
import Languages from './languages';
import Interests from './interests';
import References from './references';
import BasicsHeader from './basicheader';
import Projects from './projects';
import '../css/basicOneCol.css';

const Content = ({ resume, baseUrl, showImage}) => {

  const [webImage, setWebImage] = useState(null);

  useEffect(() => {
    // don't set the image if there is no image
    if (baseUrl && resume.basics.image) {
      const prefix = new URL(baseUrl).origin
      const path = '/storage/v1/object/public/' + resume.basics.image;
      const url = prefix + path;
      setWebImage(url);
    }
  }, [baseUrl]);

  const panes = [
    {
      menuItem: 'Resume',
      render: () => (
        <Tab.Pane>
          <DataCard dataArr={resume.work} name="Work" />
          <DataCard dataArr={resume.volunteer} name="Volunteer" />
          <Education dataArr={resume.education} name="Education" />
          <Certificates dataArr={resume.certificates} name="Certificates" />
          <Awards dataArr={resume.awards} name="Awards" />
          <Publications dataArr={resume.publications} name="Publications" />
          <Skills dataArr={resume.skills} name="Skills" />
          <Languages dataArr={resume.languages} name="Languages" />
          <Interests dataArr={resume.interests} name="Interests" />
          <References dataArr={resume.references} name="References" />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Project Portfolio',
      render: () => (
        <Tab.Pane>
          <Projects projects={resume.projects} name="Projects" />
        </Tab.Pane>
      ),
    },
  ];


  return (
    <div className='basicOneCol' id="basicOneCol">
      <div className="head">
        <Grid stackable>
          <Grid.Row>
            {showImage && (
              <Grid.Column mobile={16} tablet={16} computer={3} textAlign='center' className='image'>
                <img src={webImage} className="userImage" alt="user" />
              </Grid.Column>
            )}
            <Grid.Column mobile={16} tablet={16} computer={showImage ? 13 : 16}   className={`info ${showImage ? '' : 'hide-image'}`} >

              <BasicsHeader basics={resume.basics} name="Basics" />
              <div id="contact-info" className="contact-columns">
                <Contact contactDetails={resume.basics} name="Contact" />
                <Website contactDetails={resume.basics} name="Website" />
                <Profiles profiles={resume.basics.profiles} name="Profiles" />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div className="resumebody">
        <About summary={resume.basics.summary} name="CAREER SUMMARY" />
        <Tab panes={panes} />
      </div>
    </div>
  );
}

export default Content;
