import React from 'react';
import { Item, Header, Label, List, Icon, Segment, Container } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';

const DataCard = ({ dataArr, name }) => {
  if (!dataArr){return '';}
  const renderData = () => dataArr.map(data => {
    let endDate = data.endDate ? ` - ${data.endDate}` : " - Current";
    let entityName = name === "Work" ? data.name : data.organization;

    return (
      <Item key={entityName}>
        <Item.Content>
          <Item.Header>
            <Header as='h3'>
              <Header.Content>
              <a href={data.website} target="_blank"> {data.position}</a>
              </Header.Content>
            </Header>
          </Item.Header>
          <Item.Meta as='h4'>
          {entityName}
          </Item.Meta>
          <Item.Meta>
            {data.startDate}{endDate}
          </Item.Meta>
          <Item.Description>
          <ReactMarkdown>{data.summary}</ReactMarkdown>
           </Item.Description>
          <Item.Extra>
            {data.highlights && data.highlights.length > 0 &&
              <List>
                <List.Item>
                  <Header sub>Highlights</Header>
                  <List.List>
                    {data.highlights.map((highlight, index) => (
                      <List.Item key={index}>
                        <Icon name='right triangle' />
                       <span> {highlight}</span>
                      </List.Item>
                    ))}
                  </List.List>
                </List.Item>
              </List>
            }
          </Item.Extra>
        </Item.Content>
      </Item>
    );
  });



  return (
    <Segment id={name} className="sectionWrapper">
      <Header as='h2' className="sectionLabel">{name}</Header>
      <Item.Group >{renderData()}</Item.Group>
    </Segment>
  );
};

export default DataCard;
