import React, { useState, useEffect } from 'react';
import { Grid, Tab } from 'semantic-ui-react';

import Contact from './contact';
import Website from './website';
import About from './about';
import Profiles from './profiles';
import DataCard from './dataCard';
import Education from './education';
import Certificates from './certificates';
import Awards from './awards';
import Publications from './publications';
import Skills from './skills';
import Languages from './languages';
import Interests from './interests';
import References from './references';
import BasicsHeader from './basicheader';
import Projects from './projects';

import '../css/basicTwoCol.css';

const Content = ({ resume, baseUrl, showImage }) => {

  const [activeIndex, setActiveIndex] = useState(0);
  const [webImage, setWebImage] = useState(null);

  useEffect(() => {
    // don't set the image if there is no image
    if (baseUrl && resume.basics.image) {
      const prefix = new URL(baseUrl).origin
      const path = '/storage/v1/object/public/' + resume.basics.image;
      const url = prefix + path;
      setWebImage(url);
    }
  }, [baseUrl]);

  const panes = [
    {
      menuItem: 'Resume',
      render: () => (
        <Tab.Pane>
          <DataCard dataArr={resume.work} name="Work" />
          <DataCard dataArr={resume.volunteer} name="Volunteer" />
          <Awards dataArr={resume.awards} name="Awards" />
          <Publications dataArr={resume.publications} name="Publications" />
          <References dataArr={resume.references} name="References" />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Project Portfolio',
      render: () => (
        <Tab.Pane>
          <Projects projects={resume.projects} name="Projects" />
        </Tab.Pane>
      ),
    },
  ];

  const handleHashChange = () => {
    const [hashTab, hashTarget] = window.location.hash.substring(1).split('/');
    const tabIndex = panes.findIndex(pane => pane.menuItem.toLowerCase().replace(/\s+/g, '-') === hashTab);
    if (tabIndex >= 0) {
      setActiveIndex(tabIndex);
      if (hashTarget) {
        const targetElement = document.getElementById(hashTarget);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('hashchange', handleHashChange);
    handleHashChange(); // Check the initial hash

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);


  return (
    <Grid columns={2} stackable className='basicTwoCol' id='basicTwoCol'>
      <Grid.Row>
        <Grid.Column tablet={4} computer={4} className="first-column">
          <div className="center">
            {showImage && <img src={webImage} className="userImage" alt="user" />}
          </div>
          <BasicsHeader basics={resume.basics} name="Basics" />
          <Contact contactDetails={resume.basics} name="Contact" />
          <Website contactDetails={resume.basics} name="Website" />
          <Profiles profiles={resume.basics.profiles} name="Profiles" />
          <About summary={resume.basics.summary} name="About" />
          <Education dataArr={resume.education} name="Education" />
          <Certificates dataArr={resume.certificates} name="Certificates" />
          <Skills dataArr={resume.skills} name="Skills" />
          <Languages dataArr={resume.languages} name="Languages" />
          <Interests dataArr={resume.interests} name="Interests" />
        </Grid.Column>
        <Grid.Column tablet={12} computer={12} className="second-column resumebody">
          <Tab panes={panes} activeIndex={activeIndex} onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default Content;
