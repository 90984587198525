import React from 'react';
import { Icon } from 'semantic-ui-react';

const Contact = ({ contactDetails }) => {
  if (contactDetails == null) return '';
  return (
    <div id="website" className="sectionWrapper">
      <a href={contactDetails.url} target="_blank" rel="noopener noreferrer">
        <Icon name='linkify' />
        <span className='websiteName'>{contactDetails.url}</span>
      </a>
    </div>
  );
};

export default Contact;
