import React, { useState, useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import BasicTwoCol from './components/basicTwoCol';
import BasicOneCol from './components/basicOneCol';
import Index from './components/index';

import './css/App.css';

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY;

const sanitizePath = (path) => {
  return path.replace(/[^a-zA-Z0-9\/-]/g, ''); // Basic sanitization, adjust as needed
};

const App = () => {
  const [resume, setResume] = useState(null);
  const [classes, setClasses] = useState('');
  const [config, setConfig] = useState({
    layout: 'modern',
    theme: 'gray',
    lines: false,
    certificates: false,
    showImage: true,
    awards: false,
    languages: false,
    volunteer: false,
    publications: false,
    interests: false,
    references: false,
    headerFonts: 'sansheader',
    bodyFonts: 'sansbody'
  });
  const location = useLocation();
  const sanitizedPath = sanitizePath(location.pathname);
  const pathSegments = sanitizedPath.split('/').filter(segment => segment);


  useEffect(() => {
    const fetchResume = async () => {
      try {
        const response = await fetch(`${SUPABASE_URL}/websites_resume_view?path=eq.${sanitizedPath}&select=* `, {
          headers: {
            'apikey': SUPABASE_KEY,
            'Authorization': `Bearer ${SUPABASE_KEY}`,
          },
        });
        
     
        const data = await response.json();
        
        if (!data.length) throw new Error('not valid URL');
        
        if (data && data.length > 0) {
        
          if (data[0].config){
            setConfig(data[0].config);
          }
          setResume(data[0].json_resume);
        }
      } catch (error) {
        console.error('Failed to fetch resume data:', error);
      }
    };
    if (pathSegments.length === 2) {
      fetchResume();
    }

      const sanitizePath = (path) => {
    return path.replace(/[^a-zA-Z0-9\/]/g, ''); // Basic sanitization, adjust as needed
  };
  }, [pathSegments.length]);

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);

    let updatedConfig = { ...config };
    params.forEach((value, key) => {
      updatedConfig[key] = value === 'true' ? true : value === 'false' ? false : value;
    });
    setConfig(updatedConfig);
  }, [location.hash]); // Reacts only to changes in the URL hash

  useEffect(() => {
    const outputList = buildOutputList(config);
    setClasses(outputList);
  }, [config]); // Reacts to changes in the config object

  const buildOutputList = (config) => {
    const booleanKeys = [
      'certificates',
      'awards',
      'languages',
      'volunteer',
      'showImage',
      'publications',
      'profileImage',
      'interests',
      'references',
      'lines',
    ];
    const valueKeys = [
      'layout',
      'theme',
      'headerFonts',
      'bodyFonts',
    ];
    

    return [
      ...booleanKeys.filter((key) => config[key]),
      ...valueKeys.map((key) => config[key]),
    ].join(' ');
  };

  if (pathSegments.length === 2 && !resume) {
    return <div>Loading...</div>;
  }


  return (
    <>
      <Helmet>
        <title>{resume ? `${resume?.basics.name} - Resume` : 'Loading...'}</title>
      </Helmet>
      <Container className={classes}>
        {pathSegments.length === 2 ? (
          config.layout === 'modern' ? <BasicTwoCol resume={resume} baseUrl={SUPABASE_URL} showImage={config.showImage} /> :
          config.layout === 'classic' ? <BasicOneCol resume={resume}  baseUrl={SUPABASE_URL} showImage={config.showImage} /> :
          null
        ) : <Index />}
      </Container>
    </>
  );
};

export default App;
