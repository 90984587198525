import React from 'react';
import { Container, Icon, Segment } from 'semantic-ui-react';

const Contact = ({ contactDetails }) => (
  // Only render the contact section if there is a country. Country is a required field in the JSON Resume schema.
  contactDetails.Country ? (
  <Segment id="contact" className="sectionWrapper">
    <Container>
      <div className='contactAddress'>
        <Icon name='point'  /> 
        {`${contactDetails.location.address} ${contactDetails.location.city} ${contactDetails.location.region} ${contactDetails.location.postalCode}`}
      </div>
      <div className='contactEmail'>
        <Icon name='mail'  /> {contactDetails.email}
      </div>
      <div className='contactPhone'>
        <Icon name='phone'  /> {contactDetails.phone}
      </div>
    </Container>
  </Segment>
  ) : null
);

export default Contact;
