import React, { useCallback, useEffect } from 'react';
import { Grid, Label, Icon, Item, Header, Popup, Segment } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';
import '../css/projects.css';

const Projects = ({ projects }) => {
  if (!projects){return '';}
  const copyToClipboard = useCallback((text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(text).then(
            () => console.log('Copied to clipboard'),
            (err) => console.error('Failed to copy to clipboard', err)
        );
    } else {
        console.warn('Clipboard API not available.');
    }
}, []);

useEffect(() => {
  // Extract the fragment identifier from the URL
  const hash = window.location.hash;
  
  // Use a regular expression to extract the project number
  const projectMatch = hash.match(/#project-portfolio\/(project\d+)/);
  
  if (projectMatch && projectMatch[1]) {
    // Get the project number (e.g., "project4")
    const projectId = projectMatch[1];
    
    // Find the element with the extracted ID
    const projectElement = document.getElementById(projectId);
    
    if (projectElement) {
      // Scroll to the element
      projectElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
}, []); // Empty dependency array means this runs once when the component mounts

  return (
    <Segment id="Projects" className="sectionWrapper">
      <Header as='h2' className="sectionLabel">Projects</Header>
      <Item.Group className="sectionBody">
        {projects.map((project, index) => (
          <Item key={project.name}>
            <Item.Content>
              <Item.Header as="h3" id={`project${index + 1}`} className='projectHeader'>
                {project.name}
                <Popup
                  content="Copy link to clipboard"
                  trigger={
                    <Icon
                      name="chain"
                      className='chainIcon'
                      onClick={() => copyToClipboard(`${window.location.href.split('#')[0]}#project-portfolio/project${index + 1}`)}
                    />
                  }
                />
             
              </Item.Header>
              <Item.Description>
              <Item.Meta>Start Date: {project.startDate} - {project.endDate}</Item.Meta>
              {project.url && <p ><b><a href={project.url}>Project Website: {project.url}</a></b></p>}
            
                
                <ReactMarkdown>
                  {project.description}
                  </ReactMarkdown>
                
             
              </Item.Description>
            </Item.Content>
          </Item>
        ))}
      </Item.Group>
    </Segment>
  );
};

export default Projects;
