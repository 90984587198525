import React from 'react';
import { Header, Item, Label, Container, Segment } from 'semantic-ui-react';

const Publications = ({ dataArr, name }) => {
  if (!dataArr){return '';}
  const renderData = () => dataArr.map(data => (
    <Item key={data.name}>
      <Item.Content>
        <Header as='h3' className='publicationName'>
          {data.name}
          <div>
            {data.releaseDate}
            {data.website && (
              <Label as='a' href={data.website} target="_blank" size="mini">
                {data.website}
              </Label>
            )}
          </div>
        </Header>
        <div>Published by: {data.publisher}</div>
        <Item.Description>
          {data.summary}
          </Item.Description>
      </Item.Content>
    </Item>
  ));



  return (
    <Segment id={name} className="sectionWrapper">
      <Header as='h2' className="sectionLabel">{name}</Header>
      <Item.Group >{renderData()}</Item.Group>
    </Segment>
  );
};

export default Publications;
