import React from 'react';
import { Header, Item, Container, Segment } from 'semantic-ui-react';

const Awards = ({ dataArr, name }) => {
  if (!dataArr){return '';}
  const renderData = () => dataArr.map(data => (
    <Item key={data.title}>
      <Item.Content>
        <Header as='h3'>{data.title}
          <Header.Subheader>{data.awarder}</Header.Subheader>
        </Header>
        <Item.Meta>{data.date}</Item.Meta>
        <Item.Description>{data.summary}</Item.Description>
      </Item.Content>
    </Item>
  ));


  return (
    <Segment id={name} className="sectionWrapper">
      <Header as='h2' className="sectionLabel">{name}</Header>
      <Item.Group >{renderData()}</Item.Group>
    </Segment>
  );
};

export default Awards;
