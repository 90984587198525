import React from 'react';
import { Header, Label, Item, Segment, Progress } from 'semantic-ui-react';

const Skills = ({ dataArr, name }) => {
  if (!dataArr){return '';}
  const renderData = () => dataArr.map(data => {
    let pValue = 0;
    switch (data.level) {
      case 'Expert':
        pValue = 100;
        break;
      case 'Intermediate':
        pValue = 70;
        break;
      case 'Beginner':
        pValue = 25;
        break;
      default:
        pValue = 0;
    }

    return (
      <Item key={data.name} className="tagListContainer">
        <Item.Content>
          <Item.Header as="h3">
            {data.name}: { data.level}
            <Progress percent={pValue} />
            <Item.Description>
              
              <div>
                {data.keywords.map(keyword => (
                  <Label key={keyword}>{keyword}</Label>
                ))}
              </div>
              
            </Item.Description>
          </Item.Header>
        </Item.Content>
      </Item>
    );
  });



  return (
    <Segment id={name} className="sectionWrapper">
      <Header as='h2' className="sectionLabel">{name}</Header>
      <Item.Group className='sectionBody'>
        {renderData()}
      </Item.Group>
    </Segment>
  );
};

export default Skills;
