import React from 'react';
import { Header, Item, Segment } from 'semantic-ui-react';

const Certificates = ({ dataArr, name }) => {
  if (!dataArr){return '';}

  const renderData = () => dataArr.map((data, index) => (
    <Item key={index}>
      <Item.Content>
        <Header as='h3' className='languageName'>{data.name} by <a href={data.url} target='_blank'>{ data.issuer}</a>
        <Header.Subheader>
                {data.date}
              </Header.Subheader>
          </Header>
      </Item.Content>
    </Item>
  ));



  return (
    <Segment id={name} className="sectionWrapper">
      <Header as='h2' className="sectionLabel">{name}</Header>
      <Item.Group >{renderData()}</Item.Group>
    </Segment>
  );
};

export default Certificates;
