import React from 'react';
import { Header, Label, Item, Segment } from 'semantic-ui-react';

const Interests = ({ dataArr, name }) => {
  if (!dataArr){return '';}
  const renderData = () => dataArr.map(data => (
    <Item key={data.name} className="tagListContainer">
      <Item.Content>
        <Item.Header as='h3' className='interestsName'>{data.name}</Item.Header>
        <Item.Description>
          {data.keywords.map(keyword => (
            <Label key={keyword} className="keywords">{keyword}</Label>
          ))}
        </Item.Description>
      </Item.Content>
    </Item>
  ));



  return (
    <Segment id={name} className="sectionWrapper">
      <Header as='h2' className="sectionLabel">{name}</Header>
      <Item.Group className='sectionBody'>{renderData()}</Item.Group>
    </Segment>
  );
};

export default Interests;
